import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import Envio1 from '../images/PAQUETERIA1.svg';
import Pagos from '../images/PAGOS.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CardsWeb from './CardsWeb';
import TerminosWeb from './TerminosWeb';
import HeaderColor from "./headerColor"

export const query = graphql` 
query ($slug: String! ) {
  precio: allDatoCmsPricing(filter: {articulo: {eq: $slug}}) {
    nodes {
      titular
      id
      slug
      articulo
      descripcion
      precio
      especiales
      imagen {
        fluid(maxWidth:1200){
          ...GatsbyDatoCmsFluid
        }
      }
        layer {
          fluid(maxWidth:1200){
            ...GatsbyDatoCmsFluid
          }
        }
      
    }
  }
}

   
`


const ListadoPrecios = styled.ul`
  
max-width:1200px;
width: 95%;
margin: 4rem auto 0 auto;
@media (min-width: 768px){
    grid-template-columns: repeat(2, 1fr);
    display:grid;
    column-gap: 2rem;
}
`

const Boton = styled.button ` 
    margin-top: 2rem;
    padding: 1rem;
    background-color: #18184f;
    width: 50%;
    color: #FFF;
    display: block;
    text-decoration: none;
    text-transform:uppercase;
    font-weight:700;
    text-align:center;

`;

const PricingTemplate = ({data}) => {
    const planes = data.precio.nodes[0];
    const {titular,descripcion,precio,imagen,articulo,slug,especiales,layer} = data.precio.nodes[0];
    let producto_concatenar = '/'+slug+'/'+articulo; 
    const direccion = "https://api.whatsapp.com/send?phone=525548701797&text=Busco%20el%20Desarrollo%20de%20una%20Pagina%20";
 /*  const {precio,titular,descripcion} = pricings; */
 
    return (
      
      <Layout>
        <HeaderColor/>
        <ListadoPrecios>
        {/* IMAGEN DEL PRODUCTO */}
       {slug === "ensamblaje" ? 
       <Carousel>
            <div>
            <img alt="imagen-producto" src={imagen.fluid.src} css={css`max-width: 100%;height: auto; `}/>
            </div>
            <div>
            <img css={css` max-width: 100%; height: auto;   display: block; margin-left: auto; margin-right: auto;`} src={layer.fluid.src}/> 
            </div>
       </Carousel>
       
       :
       
       null
       }
       {slug === "mantenimiento" ? <img alt="imagen-producto" src={imagen.fluid.src} css={css`
           max-width: 100%;
           height: auto;
        
        `}/> : null }
      <div>
     {/*  INICIO SLUG ENSAMBLAJE */}

     
       {slug === 'desarrolloweb' ? null : <h1>{titular}</h1> }
      {slug==='ensamblaje' ? <div  css={css`
          font-size:  1.5rem;
          `} dangerouslySetInnerHTML={{ __html: descripcion }} /> : null }
      {slug === 'mantenimiento' ? <div  css={css`
          font-size:  1.5rem;
          `} dangerouslySetInnerHTML={{ __html: especiales }} /> :
          
          
       <div>     
               
       </div>
            }
        


        {/*   FIN SLUG ENSAMBLAJE */}

           { slug=='desarrolloweb' ?  null : <h1>${precio}.00 mxn</h1> }
           { articulo=='amedida' ?  <h1> Desarrollamos Servicios Web a medida</h1> : null }
           
           { slug === 'mantenimiento' ? 
          <div> 
            <p css={ css `font-size:1.7rem;`} >Visitanos en </p>
            <p css={ css `font-size:1.7rem;`} >Avenida  1A Numero 20 Colonia San Jose de la Escalera GAM </p>
            <p css={ css `font-size:1.3rem;`} >Con gusto te atendemos </p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3760.4233971942044!2d-99.16495669999999!3d19.523428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc907d6c1f2c54d32!2sSoluciones%20Tecnologicas%20Monarca!5e0!3m2!1ses-419!2smx!4v1603393034437!5m2!1ses-419!2smx" width="50%" height="auto" css={css`border:0;`} frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"/> <br></br></div>
     
           : null}
            { slug === 'ensamblaje' ? <div><img src={Envio1}  /><img src={Pagos}  /></div> : null}
        {/*  SLUG DE ENSAMBLAJE */}
          
        {/*    FIN SLUG ENSAMBLAJE */}
        { slug==='desarrolloweb'? 
        null
              : <button className="snipcart-add-item"
              data-item-id={articulo}
              data-item-price={precio}
              data-item-url={producto_concatenar}
              data-item-description={descripcion}
              data-item-image={imagen.fluid.src}
              data-item-name={titular}  css={css`
              margin-top: 3rem;
              padding: 1rem;
              background-color: #25184F;
              width: 100%;
              font-size: 2rem;
              color: #FFF;
              display: block;
              text-decoration: none;
              text-transform:uppercase;
              font-weight:700;
              text-align:center;
            `}>
              Agregar al Carrito
      </button> }
      </div>
   
      
        
     

        </ListadoPrecios>
{/* 
        PARTE DE DESARROLLO WEB */}
        {slug === "desarrolloweb" ? 
        <div>
         <CardsWeb  key={planes.id} planes={planes} /> 
         <TerminosWeb/>
       </div>: null}
        
        {slug === 'ensamblaje' ? 
          <div css={css` 
          align-items : center; align:center;
          `}><br></br>
            
            <div  css={css` 
          font-size:  2rem; 
          padding: 5rem;
          color: black;
          /* text-align: center; */
          `} dangerouslySetInnerHTML={{ __html: especiales }} />  <br></br>
         
            
          </div> : null}
      </Layout>
      
  
    )
}
 
export default PricingTemplate;