import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
const TerminosWeb = () => {

    const Termino = styled.p`
    font-size: 1.5rem;
    `;

    const Listado = styled.ul`
    max-width:1200px;
    width: 100%; 
    font-size: 2rem;
    margin: 2rem auto 0 auto;
    @media (min-width: 768px){
        display:grid;
        grid-template-columns: repeat(3, 2fr);
        grid-auto-rows: 1fr;
        column-gap: 2rem;
    }
`

    const Price = styled.ul`
    border-bottom: 1px solid #eee;
    padding: 13px;
    `;


    const Tabla = styled.div`
    padding: 2px 16px;
    margin-bottom: 2rem;
    font-size:2rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-bottom: 2rem;
    font-size:2rem;`;

    return ( 

       <div css={css`
       margin: auto;
       width: 70%;
;
       padding: 10px;`}>
            


            <Listado  >
            <Tabla>
            <Price css={css`font-weight:700; text-align:center;`}>Cambio Bajo Impacto</Price>
                <Price >Cambios esteticos menores, como es cambiar el color de un boton, fuentes, agregar una imagen estatica , video o el logo de la empresa</Price>
                <Price css={css`font-weight:700;`}>5 dias habiles</Price>
            </Tabla>
            <Tabla>
            <Price css={css`font-weight:700; text-align:center;`}>Cambio Mediano Impacto</Price>
                <Price >Cambio dentro de una seccion como es cambiar la funcionalidad de un cotizador, cambiar almacenamiento, añadir una funcion menor </Price>
                <Price css={css`font-weight:700;`}>7 dias habiles</Price>
            </Tabla>
            <Tabla >
            <Price css={css`font-weight:700; text-align:center;`}>Cambio Alto Impacto</Price>
                <Price >Cambio dentro de una seccion, añadir una nueva funcionalidad importante, o modificacion importante , requiere crear una nueva cotizacion</Price>
                <Price css={css`font-weight:700;`}>10 dias habiles</Price>
            </Tabla>
                
                
                
            </Listado>
            <div css={css`width:100%;   padding: 2px 16px;
          margin-bottom: 2rem;
          font-size:2rem;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);`}>
          <h5 css={css`font-size:2.3rem; font-weight:200;`} >Los tiempos de entrega son los siguientes:</h5>
          <h5 css={css`font-size:2rem; font-weight:100;`} >En los planes basicos para desde 5 dias habiles hasta 15 dias habiles segun diseño.</h5>
          <h5 css={css`font-size:2rem; font-weight:100;`} >En los planes Recomendedados para desde 10 dias habiles hasta 20 dias habiles segun diseño.</h5>
          <h5 css={css`font-size:2rem; font-weight:100;`} >En los planes Empresariales  desde 20 dias habiles hasta segun diseño.</h5>
        </div>


        <div css={css`  padding: 2px 16px;
          margin-bottom: 2rem;
          font-size:2rem;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);`} >
        <Termino>1.- Apartado Secciones: Para lel paquete de  Landing page esta contemplado el estadar de uso como es una pagina Inicio, Nosotros/Contacto , a su vez puede ser reemplazados por alguna seccion mas detallada de productos, un sistema de citas, cotizador, personalizador de productos, gestor de ventas, seguimiento a pacientes o sistema de videoconferencias  </Termino>
            <Termino>2.- Pagina Estatica: Se refiere a que es una pagina donde el contenido solo sera modificable cuando sea utilizada el administrador de contenidos (Apartado 9) </Termino>
            <Termino>3.- Brindamos apoyo para indexar la pagina a Google , posicionarla mediante SEO Organico y elaborar un posible plan de SEM para anuncios pagados</Termino>
            <Termino>4.- $600 de credito para el uso para promocionar la pagina en Google </Termino>
            <Termino>5.- Dominio .com o .com.mx para tener mejor posicionamente en busquedas en Mexico </Termino>
            <Termino>6.- Alojada en SSD que en comparacion con el Disco duro tradicional , las cargas de las paginas son más rapidas-</Termino>
            <Termino>7.- Administrador de contenidos, no necesitas mover codigo, se te capacitara para modificaciones posteriores al contenido estatico (texto/imagenes/videos)</Termino>
            <Termino>8.- Soporte por 1 Año,  lo largo del periodo estamos para consulras</Termino>
            <Termino>9.- Asesoramiento para crear aviso de privacidad y terminos de uso  </Termino>
            <Termino>10.-Limite de productos incluyendo derivados de estos  </Termino>
            <Termino>11.-Progressive Web App: Adaptamos tu pagina web para ser facilmente instalada en dispositivos moviles (Android/iOS) asi como Windows 10 con Google Chrome  </Termino>
            <Termino>* El tiempo de entrega varia entre 7 dias habiles a 90 dias habiles dependiendo el tipo de trabajo</Termino>
            <Termino>** Todo trabajo requiere un 50% de anticipo , extras generados por uso de tecnologias diversas va por cargo del cliente</Termino>
        </div>
       </div>
       

     );
}
 
export default TerminosWeb;