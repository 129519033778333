import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Tecnologias from '../images/tecnologias.svg';
import ERP from '../images/examples/ERP.svg';
import Administracion from '../images/examples/administracion.svg';
import Cotizador from '../images/examples/cotizador.svg';
import Salud from '../images/examples/salud.svg';
import Restaurante from '../images/examples/restaurantes.svg';
import Fade from 'react-reveal/Fade';
import HeadShake from 'react-reveal/HeadShake';

const CardsWeb = ({planes}) => {

    const Listado = styled.ul`
    max-width:1200px;
    width: 100%; 
    margin: 2rem auto 0 auto;
    @media (min-width: 768px){
        display:grid;
        grid-template-columns: repeat(3, 2fr);
        grid-auto-rows: 1fr;
        column-gap: 2rem;
    }
`   
    const PriceHeader = styled.ul`
    padding: 2rem;
    height:7rem;
    text-align: center;`
       const Iconos = styled.img`
       `

    const Price = styled.ul`
    padding: 2rem;
    
    text-align: center;`

    const ContenedorTotal = styled.div`
     padding: 2px 16px;
          margin-bottom: 2rem;
          font-size:2rem;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          &:hover {
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
            }   
    `;

const Titulo = styled.div`
    font-size:5rem;
    text-align:center;
    color:#e05353;
`;


    const DesIconos = styled.p`font-size: 1.8rem; text-align: justify;`;
    
    const Boton = styled.button ` 
    margin-top: 2rem;
    padding: 1rem;
    background-color: #18184f;
    width: 100%;
    color: #FFF;
    display: block;
    text-decoration: none;
    text-transform:uppercase;
    font-weight:700;
    text-align:center;
    border-radius: 25px;

    `;

const {articulo,precio,slug} = planes;

    return ( 
        <>
          <Titulo >Nuestros Planes</Titulo>
            {

                articulo === "landing"
                ?
                <div>
                
                <Listado>
              
             <ContenedorTotal
        ContenedorTotal
      > 
        <PriceHeader css={css`background-color:white;   color:#e05353;`}><h3 css={css`
         text-align: center;
        `}>Plan Basico</h3><br/></PriceHeader>
        <ul> 
        <PriceHeader css={css`background-color:white;  font-size:4rem; color: #ec9797;`}><h3 css={css`
         text-align: center;
        `}> $6,800.00</h3></PriceHeader>
          <Price>2 secciones <sup>1</sup></Price>
          <Price>Pagina estática <sup>2</sup></Price>
          <Price>SEO, orientación para SEM <sup>3</sup></Price>
          <Price>$600 crédito Google Adsence <sup>4</sup></Price>
          <Price>Dominio com.mx o .com <sup>5</sup></Price>
          <Price>Hosting SSD <sup>6</sup></Price>
          <Price>Contacto Redes Sociales </Price>
          <Price>Administrador de Contenidos para modificación de información <sup>7</sup> </Price>
          <Price>Asesoramiento legal para el uso de la página web <sup>9</sup> </Price>
          <Price>Soporte por 1 año </Price>
          <Price>Hasta un  cambio mediano impacto , dos de bajo impacto</Price>
          <Price>Progressive Web App incluida<sup>11</sup></Price>
          <Price css={css`align: center; text-align:center; `}>
        <form method="GET" action="https://wa.link/4yr291">
            <Fade><Boton type="submit">Contactanos por WhatsApp</Boton></Fade>
          </form>
         
        </Price>   
         
        </ul>
      
      </ContenedorTotal>
      <HeadShake>
      <ContenedorTotal>
        <PriceHeader css={css`background-color:white;   color:#e05353;`}><h3 css={css`
         text-align: center;
        `}>Plan Pyme (Recomendado)</h3></PriceHeader>
        <ul> 
        <PriceHeader css={css`background-color:white; font-size:4rem; color: #ec9797;`}><h3 css={css`
         text-align: center;
        `}> $12,000.00</h3></PriceHeader>
          <Price>4 secciones<sup>1</sup></Price>
          <Price>Pagina estática<sup>2</sup></Price>
          <Price>SEO, orientación para SEM <sup>3</sup></Price>
          <Price>$600 crédito Google adsence<sup>4</sup></Price>
          <Price>Dominio    com.mx o .com <sup>5</sup></Price>
          <Price>Hosting SSD <sup>6</sup></Price>
          <Price>Contacto Redes Sociales </Price>
          <Price>Administrador de Contenidos para modificación de información  </Price>
          <Price>Asesoramiento legal para la página web <sup>9</sup> </Price>
          <Price>Soporte por 1 año</Price>
          <Price>Hasta dos cambios mediano impacto , tres de bajo impacto</Price>
          <Price>Progressive Web App incluida<sup>11</sup></Price>
          <Price css={css`align: center; text-align:center; `}>
        <form method="GET" action="https://wa.link/4yr291">
          <Fade>  <Boton type="submit">Contactanos por WhatsApp</Boton></Fade>
          </form>
         
        </Price>
        </ul>
      </ContenedorTotal>
      </HeadShake>
      <ContenedorTotal
      > 
          <div align="center">  <PriceHeader css={css`background-color:white;   color:#e05353;`}><h3 css={css`
         text-align: center;
        `}>Plan Empresarial</h3><br/></PriceHeader></div>
       <PriceHeader css={css`background-color:white; color: #ec9797; font-size:4rem;`}><h3 css={css`
         text-align: center;
        `}> $18,000.00</h3></PriceHeader>
        <ul> 
          <Price>6 secciones <sup>1</sup> </Price>
          <Price>Pagina estática<sup>2</sup></Price>
          <Price>SEO, orientación para SEM <sup>3</sup></Price>
          <Price>$600 crédito Google adsence<sup>4</sup></Price>
          <Price>Dominio    com.mx o .com <sup>5</sup></Price>
          <Price>Hosting SSD <sup>6</sup></Price>
          <Price>Contacto Redes Sociales </Price>
          <Price>Administrador de Contenidos para modificación de información  </Price>
          <Price>Asesoramiento legal para la página web <sup>9</sup> </Price>
          <Price>Soporte por 1 año</Price>
          <Price>Hasta dos cambios mediano impacto , cuatro de bajo impacto</Price>
          <Price>Progressive Web App incluida<sup>11</sup></Price>
          <Price css={css`align: center; text-align:center; `}>
        <form method="GET" action="https://wa.link/4yr291">
        <Fade>  <Boton type="submit">Contactanos por WhatsApp</Boton></Fade>
          </form>
         
        </Price>
        </ul>
      </ContenedorTotal>
            </Listado>
      
            </div>
                : null  }
                {    articulo === "ecommerce"
                ?
                <Listado>
              <ContenedorTotal
      > 
       <PriceHeader css={css`background-color:white;   color:#e05353;`}><h3 css={css`
         text-align: center;
        `}>Plan Inicio</h3><br/></PriceHeader>
        <ul> 
        <PriceHeader css={css`background-color:white; font-size:4rem; color: #ec9797;`}><h3 css={css`
         text-align: center;
        `}> $8,000.00</h3></PriceHeader>
          <Price>2 secciones<sup>1</sup> </Price>
          <Price>Hasta 50 productos <sup>11</sup> </Price>
          <Price>Pagina estática <sup>2</sup> </Price>
          <Price>Pago en línea con Tarjeta de Credito, Debito</Price>
          <Price>SEO, orientación para SEM <sup>3</sup> </Price>
          <Price>$600 crédito Google adsence<sup>12</sup> </Price>
          <Price>Dominio    com.mx o .com <sup>5</sup> </Price>
          <Price>Hosting SSD <sup>6</sup> </Price>
          <Price>Contacto Redes Sociales </Price>
          <Price>Administrador de Contenidos para modificación de información <sup>7</sup>  </Price>
          <Price>Asesoramiento legal para la página web <sup>9</sup>  </Price>
          <Price>Soporte por 1 año</Price>
          <Price>Hasta 1 cambio mediano impacto , 2 de bajo impacto</Price>
          <Price>Progressive Web App incluida<sup>11</sup></Price>
          <Price css={css`align: center; text-align:center; `}>
        <form method="GET" action="https://wa.link/4yr291">
            <Boton type="submit">Contactanos por WhatsApp</Boton>
          </form>
         
        </Price>
        </ul>
      </ContenedorTotal>
      <HeadShake>
      <ContenedorTotal
      > 
           
           <PriceHeader css={css`background-color:white;   color:#e05353;`}><h3 css={css`
         text-align: center;
        `}>Plan Pyme (Recomendado) </h3></PriceHeader>
        <ul> 
        <PriceHeader css={css`background-color:white; font-size:4rem; color: #ec9797;`}><h3 css={css`
         text-align: center;
        `}> $16,000.00</h3></PriceHeader>
          <Price>4 secciones<sup>1</sup> </Price>
          <Price>Hasta 100 productos <sup>11</sup> </Price>
          <Price>Pagina estática <sup>2</sup> </Price>
          <Price>Pago en línea con Tarjeta de Credito, Debito</Price>
          <Price>SEO, orientación para SEM <sup>3</sup> </Price>
          <Price>$600 crédito Google adsence<sup>12</sup> </Price>
          <Price>Dominio    com.mx o .com <sup>5</sup> </Price>
          <Price>Hosting SSD <sup>6</sup> </Price>
          <Price>Contacto Redes Sociales </Price>
          <Price>Administrador de Contenidos para modificación de información <sup>7</sup>  </Price>
          <Price>Asesoramiento legal para la página web <sup>9</sup>  </Price>
          <Price>Soporte por 1 año </Price>
          <Price>Hasta 2 cambio mediano impacto , 3 de bajo impacto</Price>
          <Price>Progressive Web App incluida<sup>11</sup></Price>
          <Price css={css`align: center; text-align:center; `}>
        <form method="GET" action="https://wa.link/4yr291">
            <Boton type="submit">Contactanos por WhatsApp</Boton>
          </form>
         
        </Price>
        </ul>
        
      </ContenedorTotal>
      </HeadShake>
      <ContenedorTotal
      > 
      <PriceHeader css={css`background-color:white;   color:#e05353;`}><h3 css={css`
         text-align: center;
        `}>Plan Empresarial</h3><br/></PriceHeader>
        <ul> 
        <PriceHeader css={css`background-color:white; font-size:4rem; color: #ec9797;`}><h3 css={css`
         text-align: center;
        `}> $25,000.00</h3></PriceHeader>
          <Price>4 secciones<sup>1</sup> </Price>
          <Price>Hasta 100 productos <sup>11</sup> </Price>
          <Price>Pagina estática <sup>2</sup> </Price>
          <Price>Pago en línea con Tarjeta de Credito, Debito</Price>
          <Price>SEO, orientación para SEM <sup>3</sup> </Price>
          <Price>$600 crédito Google adsence<sup>12</sup> </Price>
          <Price>Dominio    com.mx o .com <sup>5</sup> </Price>
          <Price>Hosting SSD <sup>6</sup> </Price>
          <Price>Contacto Redes Sociales </Price>
          <Price>Administrador de Contenidos para modificación de información <sup>7</sup>  </Price>
          <Price>Asesoramiento legal para la página web <sup>9</sup>  </Price>
          <Price>Soporte por 1 año </Price>
          <Price>Hasta 3 cambio mediano impacto , 4 de bajo impacto</Price>
          <Price>Progressive Web App incluida<sup>11</sup></Price>
          <Price css={css`align: center; text-align:center; `}>
        <form method="GET" action="https://wa.link/4yr291">
            <Boton type="submit">Contactanos por WhatsApp</Boton>
          </form>
         
        </Price>
        </ul>
        
      </ContenedorTotal>
            </Listado>
            : null
            }
            <div>
             
             
              
              <h1 css={css`text-align: center; align-items:center;`}>Desarrollamos Aplicaciones tales como:</h1>

              <div css={css`
                            width: 100%;
                            
                            text-align: center;`}>
          <Listado>
            <div css={css`box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 19px;`}>
              <div><h2><Iconos src={ERP}/></h2><h2>E.R.P</h2><DesIconos>Realiza el seguimiento de tus clientes para poder generar una campaña más efectiva asi como monitorear que clientes estan atendiendo tus vendedores</DesIconos></div>
            </div>
            <div css={css`box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 19px;`}>
              <div><h2><Iconos src={Administracion}/></h2><h2>Administracion</h2><DesIconos >Maneja tus ventas  , asi como gestiona tus empleados para el pago de nomina y vacaciones</DesIconos>    </div>
            </div>
            <div css={css`box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 19px;`}>
           <div><h2><Iconos src={Salud}/></h2>  <h2>Salud</h2><DesIconos>Seguimiento a tus pacientes por medio de una App, ideal para seguir una dieta </DesIconos>    </div>
            </div>
            <div css={css`box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 19px;`}>
            <div><h2><Iconos src={Restaurante}/></h2><h2>Restaurante</h2> <DesIconos>Busca mejorar la experiencia de tus usuarios al contar con menu digital, control de mesas y eficiencia de tus trabajadores, te apoyamos con nofificaciones</DesIconos></div>
              
            </div>
            <div css={css`box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 19px;`}>
           <div> <h2><Iconos src={Cotizador}/></h2> <h2>Cotizador</h2><DesIconos>Valua en tiempo real tus servicios, para dar a conocer un estimado a tus clientes.</DesIconos></div>
            </div>
          </Listado>
           
                             
                        
                </div>
            </div>
            <h1 css={css`text-align: center; align-items:center; `}>Tecnologias que manejamos</h1>
            <div css={css`text-align: center; align-items:center;`}>
                <img css={css`width:50%;`} src={Tecnologias} />
              </div>

              <div>
                
              </div>
      </>
       
     );
}


export default CardsWeb;